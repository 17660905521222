export default function FeatureOverviewHeader({
  subtitle,
  title,
  textContent,
}) {
  return (
    <>
      <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
        {subtitle}
      </h2>
      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        {title}
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        {textContent}
      </p>
    </>
  );
}
