import FeatureCard from '../FeatureCard/FeatureCard';
import FeatureOverviewHeader from '../FeatureOverviewHeader/FeatureOverviewHeader';

const defaultFeatureOverviewHeaderContent = {
  subtitle: 'Grow faster',
  title: 'Everything you need to expand your client base',
  textContent: `Tell your story your way and stay compliant with customized built-in disclaimers. Increase prospecting and generate new clients.`,
};

const defaultFeatureCardContent = {
  featureCards: [
    {
      title: 'Press to start',
      subtitle: 'Designed for simplicity, run cross-platform ads simply.',
      description: 'It is easy to get started.',
    },
    {
      title: 'Secure and simple',
      subtitle:
        'Keep your password to yourself and seamlessly sign in with your email',
      description:
        'Login to access the Ads Button and the advanced email AI.',
    },
    {
      title: 'Press to start',
      subtitle: 'Run your ads where your fans respond best to your media.',
      description: 'Automatically and simultaneously across digital channels.',
    },

    {
      title: 'Dynamic spend protection',
      subtitle: `Ad algorithms manage your spend efficiently.`,
      description: `They're designed to employ your ad spend when and where there are the highest chances of success.`,
    },
    {
      title: 'Powerful engineering',
      subtitle: `World-class allocation and recommendation engines drive results.`,
      description:
        'Our ads leverage the same quantitative tools used to invest capital at mutual funds, endowments, and pension funds.',
    },
    {
      title: 'Vigilant compliance',
      subtitle: `Demystify marketing compliance with the press of a button.`,
      description: `Add your registration CRD, select your campaign type, and we'll customize dislcaimer text for your instant review and approval.`
    },
  ],
};
const dVals = [
  'M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12',
  'M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z',
  'M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15',
  'M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z',
  'M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z',
  'M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01',
];
export default function FeatureOverview({featureOverviewContent}) {
  const featureOverview =
    typeof featureOverviewContent !== 'undefined' && !!featureOverviewContent
      ? {...featureOverviewContent}
      : {
        ...defaultFeatureOverviewHeaderContent,
        ...defaultFeatureCardContent,
      };
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <FeatureOverviewHeader {...featureOverview} />
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {featureOverview?.featureCards.map((featureCard, index) => (
              <div key={index}>
                <FeatureCard
                  featureTitle={featureCard?.title}
                  bodyContent={{
                    title: featureCard?.subtitle,
                    description: featureCard?.description,
                  }}
                  d={dVals[index]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
