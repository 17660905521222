export default function FeatureCard({featureTitle, bodyContent, d}) {
  return (
    <div className="pt-6" id={featureTitle}>
      <div className="flow-root bg-gray-50 transform hover:scale-105 hover:bg-gray-100 rounded-xl px-6 pb-8 h-60 sm:h-72 lg:h-72 xl:h-60">
        <div className="-mt-6">
          <div>
            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-blue-500 to-blue-900 rounded-md shadow-lg">
              <svg
                className="animate-pulse h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={d}></path>
              </svg>
            </span>
          </div>
          <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
            {featureTitle}
          </h3>
          <div className="mx-4 mt-5 text-sm text-gray-500">
            <div className="pb-6">{bodyContent?.title}</div>
            <div className="italic tracking-wide font-thinner text-xs">
              {bodyContent?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
